import React from 'react';

import './QuotaProgress.scss';

function QuotaProgress({
  className, quotaProgress, analysisCount,
}) {
  return (
    <div>
      <div className="main-progress">
        <div className="progress">
          <div
            className={className}
            role="progressbar"
            aria-valuenow={quotaProgress}
            aria-valuemin="0"
            aria-valuemax="125"
            style={{ width: `${quotaProgress}%` }}
          >
            {analysisCount}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotaProgress;
