import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './QuotaPage.scss';
import QuotaDatePicker from '../../components/quota/QuotaDatePicker.js';
import QuotaList from '../../components/quota/QuotaList.js';

function QuotaPage() {
  const { t } = useTranslation();
  const { productsList } = useSelector((state) => state.products);

  return (
    <div className="profile-screen">
      <div className="profile-title-container">
        {
        productsList.length > 1
          ? <div className="profile-title">{t('QuotaPage.quotaTitleMultiple')}</div>
          : <div className="profile-title">{t('QuotaPage.quotaTitleSingle')}</div>
        }
        <div className="profile-border bottom-border" />
        {productsList.length <= 0 && (
          <div className="no-quota">{t('Message.noApp')}</div>
        )}
      </div>

      {productsList.length > 0 && <QuotaDatePicker />}
      {productsList.length > 0 && <QuotaList />}
    </div>
  );
}

export default QuotaPage;
