import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './QuotaDatePicker.scss';
import Button from '../button/Button.js';
import DatePicker from '../date-picker/DatePicker.js';
import { setFilter } from '../../features/filterSlice.js';
import { fetchQuotas } from '../../features/quotaSlice.js';

function QuotaDatePicker() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDateValid, setIsDateValid] = useState(false);
  const { filteredItems } = useSelector((state) => state.filters);
  const {
    fromYear, fromMonth, toYear, toMonth,
  } = filteredItems;

  const handleDateChange = async (dateSelected, newDate) => {
    const { year, month } = newDate?.c ?? {};
    if (newDate !== null && typeof newDate !== 'undefined' && !newDate.invalid) {
      setIsDateValid(true);

      dispatch(
        setFilter({
          fromYear: year,
          fromMonth: month,
          toYear: year,
          toMonth: month,
        }),
      );
    } else {
      setIsDateValid(false);
    }
  };

  const handleClick = async () => {
    try {
      await dispatch(
        fetchQuotas({
          fromYear, fromMonth, toYear, toMonth,
        }),
      ).unwrap();
    } catch (error) {
      console.error('Date range error', error);
    }
  };

  return (
    <div className="quota_picker_ctr">
      <DatePicker
        label={t('QuotaPage.monthPicker')}
        maxDate={DateTime.now()}
        dateSelected="quotaPicker"
        handleDateChange={handleDateChange}
        openTo="month"
        views={['month', 'year']}
      />

      <Button
        variant="contained"
        className={isDateValid ? 'validate-btn' : 'disabled-btn'}
        disabled={!isDateValid}
        action={handleClick}
      >
        {t('Button.validate')}
      </Button>
    </div>
  );
}

export default QuotaDatePicker;
