/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from '../services/instanceApi.js';

const initialState = {
  quotasList: [],
  status: null,
  error: null,
};

export const fetchQuotas = createAsyncThunk(
  'quotas/fetch',
  async (args, { getState }) => {
    const {
      fromYear, fromMonth, toYear, toMonth,
    } = args;
    const { productsList } = getState().products;
    try {
      const data = await Promise.all(
        productsList.map(async (item) => {
          const { productName, name } = item;
          const response = await instance.get(`/products/${productName}/quotas?fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`);
          // Add the corresponding productName to each object
          const res = response.data.map((quota) => ({
            ...quota,
            name,
          }));
          return res;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const quotaSlice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    resetQuota: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotas.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchQuotas.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.quotasList = action.payload;
      })
      .addCase(fetchQuotas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetQuota } = quotaSlice.actions;
export default quotaSlice.reducer;
