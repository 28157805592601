import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  NavigationType,
  useNavigate,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { resetActivityAnalyses } from '../features/activitySlice.js';
import { resetAnalyses } from '../features/allAnalysesSlice.js';
import { resetGenericAnalyses } from '../features/analysisSlice.js';
import { resetFilters } from '../features/filterSlice.js';
import { resetInput } from '../features/inputSlice.js';
import { resetPagination } from '../features/paginationSlice.js';
import { resetQuota } from '../features/quotaSlice.js';

function BrowserBtnHandler() {
  const [locationKeys, setLocationKeys] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navType = useNavigationType();

  useEffect(() => (() => {
    let isMounted = false;
    // handle state if url is updated
    if (!isMounted) {
      dispatch(resetFilters());
      dispatch(resetAnalyses());
      dispatch(resetGenericAnalyses());
      dispatch(resetActivityAnalyses());
      dispatch(resetPagination());
      dispatch(resetInput());
      if (location.pathname !== '/quota') {
        // Do not reset quota if quota page is already displayed
        dispatch(resetQuota());
      }
    }
    return () => {
      isMounted = true;
    };
  }), [location]);

  useEffect(() => {
    let isMounted = false;
    if (!isMounted) {
      if (navType === NavigationType.Pop) {
        setLocationKeys([location.key]);
      }

      if (navType === NavigationType.Push) {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([...keys]) => keys);
          // Handle forward event
          navigate('/home');
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle backward event
        }
      }
    }
    return () => {
      isMounted = true;
    };
  }, [navType]);

  return null;
}

export default BrowserBtnHandler;
