/* eslint-disable no-unused-expressions */
/**
 * Router configuration - all available routes in the WebApp
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AuthRoutes, ProtectedRoutes } from './AuthRoutes.js';
import OfflineBoundary from '../components/offlineFallback/OfflineFallback.js';
import ResetPassRoutes from './ResetPassRoutes.js';
import NotFoundPage from '../screens/404/NotFoundPage.js';
import AnalysisReportPage from '../screens/app-analysis-report/AnalysisReportPage.js';
import DashboardPage from '../screens/app-dashboard/DashboardPage.js';
import UploadPage from '../screens/app-transmission/UploadPage.js';
import ConfirmPasswordPage from '../screens/confirm-password/ConfirmPasswordPage.js';
import ConfirmUserPage from '../screens/confirm-user/ConfirmUserPage.js';
import EditTagsPage from '../screens/edit-tags/EditTagsPage.js';
import HomePage from '../screens/home/HomePage.js';
import LegalPage from '../screens/legals/LegalPage.js';
import LoginPage from '../screens/login/LoginPage.js';
import { PrivacyPolicy } from '../screens/privacy-policy/PrivacyPolicy.js';
import ProfilePage from '../screens/profile/ProfilePage.js';
import QuotaPage from '../screens/quota/QuotaPage.js';
import ResetPasswordPage from '../screens/reset-password/ResetPasswordPage.js';

function RouterConfig(props) {
  const { productId } = props;
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOnline) {
    return <OfflineBoundary />;
  }

  return (
    <Routes>
      <Route>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/reset" element={<ResetPasswordPage />} />
        <Route path="/register/confirmation" element={<ConfirmUserPage />} />
      </Route>

      <Route element={<ResetPassRoutes />}>
        <Route path="/reset/confirmation" element={<ConfirmPasswordPage />} />
      </Route>

      <Route path="/legal-information" element={<LegalPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route element={<AuthRoutes />}>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/edit-tag" element={<EditTagsPage />} />
        <Route path="/quota" element={<QuotaPage />} />
      </Route>

      <Route element={<ProtectedRoutes accessProp={productId} />}>
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/analysis-report" element={<AnalysisReportPage />} />
      </Route>

      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

const mapStateToProps = (state) => ({
  productId: state.products.product?.productId,
});

export default connect(mapStateToProps)(RouterConfig);
