import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Nav, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown } from 'react-icons/io/index.js';

import './Navbar.scss';
import LanguagePicker from '../language-picker/LanguagePicker.js';
import SideBar from '../sidebar/SideBar.js';
import logo from '../../assets/images/dilepix_logo_vertical.png';
import { ReactComponent as UserProfile } from '../../assets/images/user_profile.svg';
import ShortcutAppContainer from '../../containers/ShortcutAppContainer.js';
import { apiSlice } from '../../features/api/apiSlice.js';
import { fetchLogout } from '../../features/userSlice.js';

function Navbar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.user);
  const { productsList } = useSelector((state) => state.products);

  const home = (event) => {
    event.preventDefault();
    navigate('/home');
  };

  const profile = async (event) => {
    event.preventDefault();
    navigate('/profile');
  };

  const quotas = async (event) => {
    event.preventDefault();
    navigate('/quota');
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    dispatch(fetchLogout());
    dispatch(apiSlice.util.resetApiState());
    navigate('/login');
  };

  const showNavbar = pathname === '/login'
    || pathname === '/reset'
    || pathname === '/reset/confirmation'
    || pathname === '/register/confirmation'
    || pathname === '/legal-information'
    || pathname === '/privacy-policy'
    || pathname === '/404';

  return !showNavbar ? (
    <div className="navbar">
      <ShortcutAppContainer {...props} />
      <div className="logo">
        <Nav.Link onClick={home} draggable="false">
          <img alt="Dilepix logo" src={logo} className="logo" draggable="false" />
        </Nav.Link>
      </div>
      <Nav className="ml-auto">
        <LanguagePicker iconSize={30} iconHeight={30} flagSize={30} />
        <SideBar {...props} />
        <Dropdown>
          <Dropdown.Toggle className="nav-profile nav-link">
            <div className="icon-circle">
              <UserProfile className="profile-icon" />
              <div className="nav-user">{username}</div>
              <IoMdArrowDropdown size={20} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu align={{ lg: 'end' }}>
            <div className="dropdown-user">{username}</div>
            <Dropdown.Item className="dropdown-element" onClick={profile}>
              {t('Navbar.headerBar.profilePage')}
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-element" onClick={quotas}>
              {productsList.length > 1 ? t('Navbar.headerBar.quotaPageMultiple') : t('Navbar.headerBar.quotaPageSingle')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="dropdown-element" onClick={handleLogout}>
              {t('Navbar.headerBar.logoutButton')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  ) : null;
}

export default Navbar;
