import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import QuotaItem from './QuotaItem.js';
import './QuotaList.scss';
import LoaderDots from '../loader/Loader.js';
import { fetchQuotas } from '../../features/quotaSlice.js';

function QuotaList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const currentMonth = DateTime.now();

  useEffect(() => {
    const { year, month } = currentMonth?.c ?? {};
    const fetchData = async () => {
      setIsLoading(true);
      try {
        dispatch(fetchQuotas({
          fromYear: year, fromMonth: month, toYear: year, toMonth: month,
        })).unwrap();
      } catch (error) {
        setIsError(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isError) {
    return (
      <div className="quota_wrapper">
        <div>{t('Alerts.quota%Error')}</div>
      </div>
    );
  }

  return (
    <div className="quota_wrapper">
      {isLoading ? (
        <div className="loader-dots">
          <LoaderDots />
        </div>
      ) : (<QuotaItem />)}
    </div>
  );
}
export default QuotaList;
